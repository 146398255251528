import "./UnsupportedBrowser.scss";

import React, { SFC } from "react";
import { analytics } from "spa-analytics-wrapper";

import logo from "./images/logo.png";

const UnsupportedBrowser: SFC = () => {
	window.document.title = "Unsupported Browser - TrendWeight";
	analytics.track();

	return (
		<>
			<section className="hero is-primary outdated">
				<div className="hero-body">
					<div className="container">
						<img src={logo} alt="Logo" className="logo" />
					</div>
				</div>
			</section>
			<section className="warn">
				<div className="container">
					<h1>
						Your browser is out-of-date<span className="emoji">:(</span>
					</h1>
					<p>You will need to use a modern browser to view this website correctly. Sorry for any inconvenience.</p>
					<p>
						<a className="button is-primary" href="http://outdatedbrowser.com/">
							Update your browser now
						</a>
					</p>
				</div>
			</section>
			<section className="copywrite">
				<div className="container">
					&copy; 2012-2019 Erv Walter&nbsp;&nbsp;&bull;&nbsp;&nbsp;
					<a href="mailto:support@trendweight.com">Contact Support</a>
				</div>
			</section>
		</>
	);
};

export default UnsupportedBrowser;
