import "react-app-polyfill/ie9";
import "core-js";
import "regenerator-runtime/runtime";

import "./index.scss";

import axios from "axios";
import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { analytics } from "spa-analytics-wrapper";

import UnsupportedBrowser from "./UnsupportedBrowser";
import lazy from "./utils/Lazy";
import version from "./utils/Version";

const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href") || undefined;
const rootElement = document.getElementById("trendweight");
const isSupported = typeof Proxy !== "undefined" && typeof Symbol !== "undefined" && true;

const App = lazy(() => import(/* webpackChunkName: "app" */ "./components/App"));

analytics.init({
	gaugesIdentifier: "5c94e5859482cb34c9ee7690",
// 	matomoIdentifier: {
// 		hostname: "matomo.trendweight.com",
// 		siteId: "beta.trendweight.com"
// 	},
// 	sentryDSN: "https://45659b5ed9cd4d109c8d67b96bf8e8e8@sentry.io/1531741",
// 	sentryRelease: version
});

// setup Axios defaults
if (process.env.NODE_ENV !== "production") {
	axios.defaults.baseURL = "https://localhost:5001";
} else {
	axios.defaults.baseURL = "https://api.trendweight.com";
}

if (isSupported) {
	ReactDOM.render(
		<BrowserRouter basename={baseUrl}>
			<Suspense fallback={null}>
				<App />
			</Suspense>
		</BrowserRouter>,
		rootElement
	);
} else {
	ReactDOM.render(<UnsupportedBrowser />, rootElement);
}
